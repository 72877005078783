@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Diğer stil kuralları */


body {
  background: linear-gradient(90deg,#9330ff,#444aec);
}

  
  a {
    color: white;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  button {
    background: transparent;
    border: none;
    cursor: pointer;
  }
    
  footer {
    bottom: 0;
    width: 100%;
  }

  .hide-scroll-bar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }
  .hide-scroll-bar::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }

  .highlighted-text {
    color: #ff6f00;  /* Daha koyu bir sarı ton */
    font-weight: bold;  /* Kalın metin */
  }
  
  footer {
    margin-top: auto;
  }
  